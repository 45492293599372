import React from 'react';
import { connect } from 'react-redux';
import {
  object, string, number,
  bool
} from 'prop-types';
import get from 'lodash/get';
import PhysicalDeliveryDetails from './PhysicalDeliveryDetails';
import PhysicalReturnDetails from './PhysicalReturnDetails';
import DigitalDeliveryDetails from './DigitalDeliveryDetails';
import DigitalReturnDetails from './DigitalReturnDetails';

const DeliveryReturns = ({
  storeListings,
  listingSlug,
  activeProductIndex,
  fulfilledInMexico
}) => {
  const deliveryDetails = get(
    get(storeListings[listingSlug], `products[${activeProductIndex}]`),
    'deliveryDetails'
  );

  const shipDate = new Date(
    get(deliveryDetails, 'estimatedShipDate', '').replace(/-/g, '/') // Replace '-' with '/' eg. 2020-12-10 -> 2020/12/10
  );

  return (
    <>
      { deliveryDetails && (
        <>
          <span className="form__label">Delivery Details</span>
          { deliveryDetails.type === 'physical' ? (
            <PhysicalDeliveryDetails shipDate={ shipDate } fulfilledInMexico={ fulfilledInMexico } />
          ) : (
            <DigitalDeliveryDetails />
          ) }

          <span className="form__label">Return Details</span>
          { deliveryDetails.type === 'physical' ? (
            <>
              <ul className="mb2">
                <li>
                  If your order arrives damaged, misprinted, or defective, we&apos;ll gladly replace it or issue a refund within 30 days of delivery date. However, since all products are made to order, we&apos;re unable to accept returns or exchanges for different sizes, colors, or change of mind.
                </li>
              </ul>
              <PhysicalReturnDetails
                listingSlug={ listingSlug }
                activeProductIndex={ activeProductIndex }
              />
            </>
          ) : (
            <DigitalReturnDetails />
          ) }
        </>
      ) }
    </>
  );
};

DeliveryReturns.propTypes = {
  storeListings: object.isRequired,
  listingSlug: string.isRequired,
  activeProductIndex: number.isRequired,
  fulfilledInMexico: bool.isRequired
};

const mapStateToProps = ({ storeListings }) => ({ storeListings });

export default connect(mapStateToProps)(DeliveryReturns);
